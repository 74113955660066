import React, { Component } from 'react';
import './App.css';
import Web3 from 'web3';
import ETHSwap from '../abis/ETHSwap.json';
import SenalToken from "../abis/SenalToken.json"
import Navbar from './Navbar'
import Main from './Main'


class App extends Component {

 async componentWillMount(){

  await this.loadWeb3()
  await this.loadBlockchainData()
  }

  async loadBlockchainData(){

    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0]})

    const ETHBalance = await web3.eth.getBalance(this.state.account)
    this.setState({ ETHBalance : ETHBalance})

    //Load Token
    const networkId = await web3.eth.net.getId()
    const tokenData = SenalToken.networks[networkId]

      if(tokenData){

        const senaltoken = new web3.eth.Contract(SenalToken.abi,tokenData.address)
        this.setState({ token: senaltoken })
        let tokenBalance = await senaltoken.methods.balanceOf(this.state.account).call()
        this.setState({tokenBalance: tokenBalance.toString()})

      }else{
          window.alert('Token contract not deployed to the Network !!')
      }


      //Load ETHSwap
    const ETHSwapData = ETHSwap.networks[networkId]
      if(ETHSwapData){
        const ethswap = new web3.eth.Contract(ETHSwap.abi,ETHSwapData.address)
        this.setState({ eth: ethswap })


      }else{
          window.alert('ETHSwap contract not deployed to the Network !!')
      }

      this.setState({loading: false})
  }

 async loadWeb3(){

    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask !')
    }
  }


buyTokens = (etherAmount) => {
    this.setState({loading: true})
    this.state.eth.methods.buyTokens().send({value: etherAmount, from: this.state.account}).on('transactionHash',(hash) => {
      this.setState({loading: false})
    })


}

sellTokens = (tokenAmount) => {
  this.setState({loading: true})
  this.state.token.methods.approve(this.state.eth.address, tokenAmount).send({ from: this.state.account}).on('transactionHash',(hash) => {
    this.state.eth.methods.sellTokens(tokenAmount).send({ from: this.state.account}).on('transactionHash',(hash) => {
    this.setState({loading: false})
    })
  })


}


  constructor(props){
    super(props)
    this.state = {

      account: '',
      token: {},
      eth: {},
      ETHBalance: '0',
      tokenBalance: '0',
      loading: true
    }
   
  }

  render() {

    let content 
    if(this.state.loading){
      content = <p id="loader" className="text-center">Loading...</p>
    }else{
      content = <Main
        ETHBalance={this.state.ETHBalance}  
        tokenBalance={this.state.tokenBalance}
        buyTokens={this.buyTokens}
        sellTokens={this.sellTokens}
        />
    }


    return (
      <div>
        <Navbar account={this.state.account}/>
        <div className="container-fluid mt-5">
          <div className="row">
            <main role="main" className="col-lg-12 ml-auto mr-auto " style={{maxWidth: '600px'}}>
              <div className="content mr-auto ml-auto">
                <a
                  href="http://www.dappuniversity.com/bootcamp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                                  </a>
                    {content}
            </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
